@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
}

.page-inner {
  max-width: $content-max-width;
  width: 100%;
}

.page-label {
  @include page-label;
}

.title {
  @include page-title;

  span {
    display: block;
  }
}

.free-text {
  @include free-text(light);

  .icon-list {
    margin-top: $spacing-sm;

    li {
      margin-bottom: $spacing-xs;
      padding-left: 22px;
      position: relative;

      &::before {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        display: block;
      }

      span {
        @include absolute(x x x 0);
        color: $c-bright-blue;
      }
    }
  }
}

.menu-icon {
  top: 3px;
  width: 5px;
}

.share-icon {
  top: 0;
  width: 15px;
}

.add-to-home-icon {
  top: 3px;
  width: 16px;
}
