@mixin nav {
  @include flex(row);
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-base;
  max-width: $content-max-width;
  width: 100%;

  @include device(($iPhone678Plus, $iPhoneX, $Pixel3XL)) {
    height: $nav-size;
    margin-bottom: $spacing-md;
  }

  @include device($Pixel3) {
    height: auto;
    margin-bottom: $spacing-sm;
  }
}
