@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
}

.page-inner {
  @include flex(column, 1);
  max-width: $content-max-width;
  width: 100%;
}

.page-label {
  @include page-label;
}

.page-title {
  @include h1;
  @include big-spacing(margin-bottom);
}

.step-list {
  @include flex(column, 1);
  counter-reset: step-list;
}

.step-list__item {
  @include big-spacing(margin-bottom);
  counter-increment: step-list;
}

.step-list__item__title {
  @include h1;
  margin-bottom: $spacing-sm;

  &::before {
    @include page-label;
    content: counter(step-list) '.';
    display: block;
  }
}

.check-in-btn {
  @include button($theme: light);
  @include margin(x auto);
  flex-direction: column;
  font-size: $fs-base;
  margin-bottom: $spacing-xs;
  padding-bottom: 0;
}
