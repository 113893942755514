@import 'abstracts';
$bp-island: $content-max-width;

.container {
  @include flex(column, 1);
  @include modal-with-top-offset($spacing-sm);
  z-index: $z-modal;

  @include mq($bp-island) {
    @include site-inline-spacing;
  }
}

.onboarding {
  @include margin(x auto);
  @include flex(column, 1);
  height: inherit;
  max-width: $bp-island;
  width: 100%;
}

.onboarding-inner {
  @include flex(column, 1);
  @include size(100%, inherit);
  background-color: $c-white;
  border-top-left-radius: $page-radius;
  border-top-right-radius: $page-radius;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: $spacing-base;

  @include mq($bp-island) {
    box-shadow: $shadow-big;
  }
}

.onboarding__content {
  @include flex(column, 1);
  color: $c-dark-blue;
  opacity: 0;
  outline: none;
  padding-top: $spacing-base;
  position: relative;
  width: 100%;
}

.onboarding__screen {
  @include flex(column, 1);
  @include absolute(x x x -5000px);
  @include size(0);
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
}
