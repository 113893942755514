@import 'abstracts';
.image {
  opacity: 0;
  transition: opacity 300ms ease-out;

  &--loaded {
    // If class is applied on mount, no transition will play
    opacity: 1;
  }
}
