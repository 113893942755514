@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
}

.page-inner {
  @include flex(column, 1);
  max-width: $content-max-width;
  width: 100%;
}

.page-label {
  @include page-label;
}

.title {
  @include h1;
  @include page-title;
}

.free-text {
  @include flex($grow: 1);
  @include free-text(light);
  margin-bottom: $spacing-xl;
}

.link-wrapper {
  @include flex(row);
  justify-content: center;
  margin-bottom: $spacing-md;
}

.contact-link {
  @include button($theme: light);
}

.notice {
  border-top: 2px solid $c-disabled-bg;
  font-style: italic;
  padding-top: $spacing-base;
}
