@import 'abstracts';
.locations__title {
  @include page-label;
}

.locations__question {
  @include h1;
}

.building-list {
  display: grid;
  grid-gap: $spacing-md;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  margin-top: $spacing-md;
  max-width: $content-max-width;
}

.building-list__item {
  @include flex(column);
  width: 100%;

  &:nth-child(2n + 2) {
    transform: translateY(50%);

    &:last-child {
      margin-bottom: 25%;
    }
  }
}

.buildings__image-wrapper {
  @include flex(column, 1);
  position: relative;
  width: 100%;
}

.buildings__image-wrapper__inner {
  @include flex(column, 1);
  background-color: $c-blue;
  box-shadow: focus-highlight(0);
  height: 0;
  padding-top: calc(450% / 3);
  position: relative;
  transition: box-shadow 100ms $ease-out-quad;

  .building-link:focus & {
    box-shadow: focus-highlight(5px);
  }
}

.building-link {
  color: $c-white;
  outline: 0;
}

.building-link__image {
  @include absolute(0 x x 0);
  @include size(100%);
  object-fit: cover;
}

.building-link__text {
  @include body;
  margin-top: 5px;
}
