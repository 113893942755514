@import 'abstracts';
.question__details__header {
  li {
    @include flex(row);
    @include h2;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  ul li {
    &::before {
      @include size(0.4em);
      background-color: $c-blue;
      border-radius: 50%;
      content: '';
      flex-shrink: 0;
      margin-right: 0.4em;
      transform: translateY(0.35em);
    }
  }
}

.question__details__content {
  @include free-text;
  margin-bottom: $spacing-base;
}

.question__details__toggle {
  @include button-link;
  @include flex(row);
  align-items: center;
}

.question__details__toggle__icon {
  transform: rotate(-90deg);
  transform-origin: center center;

  &--open {
    transform: rotate(90deg);
  }
}

.question__details__content[aria-hidden='true'] {
  @include absolute(x x x -5000px);
  @include size(0);
  overflow: hidden;
  visibility: hidden;
}
