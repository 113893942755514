@import 'abstracts';
.form {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
  overflow: hidden;
  width: 100%;

  &--transition {
    cursor: wait;
  }
}

.form__screen {
  @include flex(column, 1);
  @include site-spacing(bottom);
  @include absolute(x x x -5000px);
  @include size(0);
  max-width: $content-max-width;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
}

.form__submit {
  @include flex(row);
  justify-content: center;
  margin-bottom: $spacing-xs;
  width: 100%;
}

.form__submit__button {
  @include button;
}
