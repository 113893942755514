@import 'abstracts';
$fade-duration: 500ms;

.splash {
  @include fixed(0);
  @include flex(column, 1);
  @include size(100%);
  background-color: rgb(var(--spot-color, hex-to-rgb($c-bg)));
  opacity: 0;
  overflow: hidden;
  transition: background-color 300ms ease-out;
  visibility: hidden;
  z-index: $z-splash;
}

.splash__content {
  @include flex(column, 1);
  @include site-block-spacing;
  @include site-inline-spacing;
  @include margin($spacing-lg auto);
  align-items: center;
  color: rgb(var(--spot-text-color, hex-to-rgb($c-text)));
  justify-content: center;
  max-width: $content-max-width;
  opacity: 0;
  width: 100%;
  z-index: $z-splash-content;
}

.splash__content__title {
  @include flex(column, 1);
  @include selection(
    rgb(var(--spot-color, hex-to-rgb($c-bg))),
    var(--spot-text-color, hex-to-rgb($c-text))
  );
  align-items: center;
  justify-content: center;
  text-align: center;
}

.splash__content__logo {
  color: currentColor;
  height: 32px;
}

.splash__placeholder {
  @include absolute(0 x x 0);
  @include size(100%);
}

.splash__placeholder__content {
  @include absolute(0 x x 0);
  background-color: rgb(var(--spot-secondary-color, hex-to-rgb($c-blue)));
  opacity: 0;
  transform-origin: top right;
}
