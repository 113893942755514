@mixin button-link($theme: dark) {
  $text-color: $c-white;
  $select-color: $c-bg;
  $shadow-color: $c-white;

  @if $theme == light {
    $text-color: $c-blue;
    $select-color: $c-white;
    $shadow-color: #0059ff;
  }

  @include flex(row);
  @include reset-input;
  @include small;
  @include selection($select-color, $text-color);
  align-items: center;
  color: $text-color;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: transform 100ms $ease-out-quad;
  z-index: 2;

  &::after {
    @include absolute(-3px x x -4px);
    @include size(calc(100% + 8px), calc(100% + 6px));
    background: transparent;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba($shadow-color, 0.4);
    content: '';
    transition: box-shadow 100ms $ease-out-quad;
    z-index: -1;
  }

  &:focus {
    &::after {
      box-shadow: 0 0 5px 2px rgba($shadow-color, 0.4);
    }
  }

  &:active {
    transform: scale(0.95);

    &::after {
      box-shadow: 0 0 3px 2px rgba($shadow-color, 0.4);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  span,
  i {
    @include size($fs-xs);
    margin-right: 3px;
  }
}
