@import 'abstracts';
$bp-island: $content-max-width;

.container {
  @include flex(column, 1);
  @include modal-with-top-offset($spacing-sm);
  z-index: $z-modal;

  @include mq($bp-island) {
    @include site-inline-spacing;
  }
}

.help-modal {
  @include flex(column, 1);
  @include margin(x auto);
  align-items: center;
  height: inherit;
  max-width: $bp-island;
  z-index: $z-modal;
}

.help-modal-inner {
  @include flex(column, 1);
  @include size(100%, inherit);
  background-color: $c-white;
  border-top-left-radius: $page-radius;
  border-top-right-radius: $page-radius;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: $spacing-sm;
  visibility: hidden;

  @include mq($bp-island) {
    box-shadow: $spacing-sm;
  }
}

.help-modal__content {
  @include flex(column, 1);
  @include site-inline-spacing;
  color: $c-dark-blue;
}

.help-modal__header {
  @include site-inline-spacing;
  display: flex;
  justify-content: flex-end;
}

.help-modal__header__title {
  @include page-label;
  @include selection($c-white, $c-blue);
}

.help-modal__header__description {
  @include h1;
  @include selection($c-white, $c-bg);
}

.help-modal__close {
  @include reset-input;
  @include button(md, $theme: light);
}

.help-modal__close__icon {
  display: block;
  height: $spacing-base;
  margin-top: 5%;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.help-modal__close__text {
  @include hide;
}

.free-text {
  @include free-text($theme: light);
  @include flex($grow: 1);
  margin: $spacing-lg 0;

  * {
    @include selection($c-white, $c-bg);
  }

  a {
    border-color: $c-light-blue;
  }
}

.link-wrapper {
  @include flex(row);
  justify-content: center;
  margin-bottom: $spacing-xs;
}

.contact-link {
  @include button;
  margin-bottom: 10px;
}

.notice {
  border-top: 2px solid $c-blue;
  font-style: italic;
  padding-top: $spacing-base;
}
