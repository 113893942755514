@mixin jumbo {
  @include fluid-type(
    (
      $bp-xxs: $fs-xl,
      $bp-sm: $fs-xxl,
    )
  );
  font-family: $ff-display;
  font-size: $fs-xl;
  font-weight: 300;
  line-height: 1;
}

@mixin h1 {
  @include fluid-type(
    (
      $bp-xxs: $fs-base,
      map-get($iPhone678Plus, 'width'): $fs-lg,
    )
  );
  font-family: $ff-display;
  font-weight: 400;
  line-height: 1.1;
}

@mixin h2 {
  @include fluid-type(
    (
      $bp-xxs: $fs-base,
      map-get($iPhone678Plus, 'width'): $fs-md,
    )
  );
  font-family: $ff-display;
  font-weight: 400;
  line-height: 1.1;
}

@mixin body {
  @include fluid-type(
    (
      $bp-xxs: $fs-xxs,
      map-get($iPhone678Plus, 'width'): $fs-sm,
    )
  );
  font-family: $ff-text;
  font-weight: 400;
  line-height: 1.4;
}

@mixin small {
  font-family: $ff-text;
  font-size: $fs-xs;
  font-weight: 400;
  line-height: 1.4;
}

@mixin counter {
  font-family: $ff-display;
  font-size: $fs-base;
  font-weight: 300;
  line-height: 1;
}

// **********************
// Applied
// **********************

@mixin page-label {
  @include selection($c-blue-text);
  color: $c-blue-text;
  margin-bottom: 2px;
}

@mixin page-title {
  margin-bottom: $spacing-xl;
  max-width: 325px;
}
