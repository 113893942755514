@import 'abstracts';
.page {
  outline: none;
}

.offsetTop {
  padding-top: $android-download-banner-height;
}

.skip-to-content {
  @include absolute(0 x x 0);
  @include small;
  @include padding($spacing-sm $spacing-base);
  background-color: $c-white;
  color: $c-blue;
  pointer-events: none;
  text-align: center;
  width: 100%;
  z-index: $z-skip-to-content;

  &:not(:focus) {
    opacity: 0;
  }
}

.layout {
  @include flex(column, 1);
  @include site-spacing(top);
  @include margin(x auto);
  background-color: rgba(var(--spot-color, hex-to-rgb($c-bg)), 1);
  min-height: var(--full-height, 100vh);
  transform-origin: center top;
  transition: border-radius 125ms 125ms $ease-out-quad, background-color 300ms $ease-out-sine,
    transform 250ms $ease-out-quad;
  width: 100%;

  @media (display-mode: standalone) {
    // If in PWA, just use 100vh as standard
    min-height: 100vh;
  }

  @include android-chrome {
    // On Android Chrome, the browser UI cuts into the viewport.
    min-height: calc(100vh - #{$android-chrome-address-bar-height});
  }

  &--hidden {
    opacity: 0;
    transition: visiblity 0ms 300ms ease-out, opacity 300ms ease-out;
    visibility: hidden;
  }

  &--minimized {
    border-radius: $page-radius;
    max-height: var(--full-height, 100vh);
    overflow-y: hidden;
    transform: scale(0.95) translateY($spacing-sm);
    transition: border-radius 125ms $ease-out-quad, background-color 300ms $ease-out-sine,
      transform 200ms 50ms $ease-out-quad;

    @supports (padding-top: env(safe-area-inset-top)) {
      // If on iPhone X, add space for the safe area inset size
      transform: scale(0.95) translateY($spacing-sm) translateY(env(safe-area-inset-top, 0));
    }
  }
}

.app-header {
  @include flex(row);
  @include site-inline-spacing;
  justify-content: center;
  width: 100%;
}

.app-header-inner {
  @include nav;
  justify-content: flex-end;

  &:not(:first-child) {
    display: none;
  }
}

.main {
  @include flex(column, 1);
  position: relative;
  z-index: 10;

  &--with-transition {
    cursor: progress;
  }
}

.main__content {
  @include absolute(0 x x 0);
  @include flex(column, 1);
  opacity: 0;
  width: 100%;
}
