@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
  overflow-x: hidden;
}

.main__content {
  @include flex(column, 1);
  margin-bottom: $spacing-md;
  max-width: $content-max-width;
}

.header {
  @include h1;
}

.header__label {
  @include page-label;
}

.description,
.notes {
  @include body;
  margin-top: $spacing-md;
}

.notes__list {
  margin-top: $spacing-xxs;
}

.notes__item {
  @include body;
  margin-left: $spacing-sm;
  margin-top: $spacing-xxs;
  position: relative;

  &::before {
    @include absolute(1em x x -0.4em);
    @include size(0.65em);
    background-color: $c-bright-blue;
    border-radius: 50%;
    content: '';
    transform: translate(-100%, -100%);

    @include device($iPhoneX) {
      @include absolute(1em x x -0.4em);
      @include size(0.6em);
    }
  }
}

.page__link {
  @include reset-input;
  color: $c-white;
  text-decoration: underline;
  text-decoration-color: $c-bright-blue;

  &:visited {
    color: $c-white;
  }
}

.footer {
  @include flex(column);
  align-items: center;
  width: 100%;
}

.get__app__button {
  @include button;
  margin-bottom: $spacing-md;

  @include device($iPhoneX) {
    margin-bottom: $spacing-lg;
  }
}

.footer__links {
  @include flex(row);
  background-color: $c-white;
  max-width: $content-max-width + 2 * $spacing-sm;
  outline: none;
  width: 100vw;
}

.footer__link {
  @include reset-input;
  @include padding($spacing-sm x x x);
  @include site-spacing(bottom);
  flex: 1 1 50%;
  text-align: center;
  text-decoration: none;

  &:first-child {
    border-right: 1px solid $c-dark-blue;
  }

  &:last-child {
    border-left: 1px solid $c-dark-blue;
  }
}

.footer__link__text {
  @include body;
  color: $c-dark-blue;
  display: block;
  padding-top: 0.4em;
}
