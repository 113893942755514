@mixin site-spacing-with-safe-area-inset($position, $base-size) {
  padding-#{$position}: $base-size;

  @supports (padding-#{$position}: env(safe-area-inset-#{$position})) {
    // If on iPhone X, add space for the safe area inset size
    padding-#{$position}: calc(#{$base-size} + env(safe-area-inset-#{$position}, 0));
  }
}

@mixin site-spacing($position) {
  @if $position == left or $position == right {
    padding-#{$position}: $spacing-sm;
  } @else if $position == top {
    @include site-spacing-with-safe-area-inset(top, $spacing-sm);
  } @else if $position == bottom {
    @include site-spacing-with-safe-area-inset(bottom, $spacing-base);
  }
}

@mixin site-inline-spacing {
  @include site-spacing(left);
  @include site-spacing(right);
}

@mixin site-block-spacing {
  @include site-spacing(top);
  @include site-spacing(bottom);
}

@mixin left-inset-spacing {
  @include fluid(
    padding-left,
    (
      $bp-xs: $spacing-md,
      $bp-md: $spacing-lg,
    )
  );
}

@mixin big-spacing($property) {
  @include fluid(
    $property,
    (
      $bp-xxs: $spacing-md,
      $bp-md: $spacing-xl,
    )
  );
}
