@import 'abstracts';
.page {
  @include flex(column, 1);
  align-items: center;
  overflow-x: hidden;
  width: 100%;
}

.page__inner {
  @include flex(column, 1);
  @include left-inset-spacing;
  @include site-spacing(right);
  margin-bottom: $spacing-lg;
  max-width: $content-max-width;
  width: 100%;
}

.page__stop-icon {
  height: 70px;
  margin-bottom: $spacing-md;
}

.page__header {
  @include flex(column);
  align-items: flex-start;
}

.page__header__title {
  margin-bottom: $spacing-md;
  max-width: 275px;
}

.page__notes {
  @include flex(column, 1);
  max-width: 300px;
}

.page__notes__item {
  @include body;
  position: relative;

  &::before {
    @include absolute($fs-sm x x -7px);
    @include size(8px);
    background-color: $c-gray-blue;
    border-radius: 50%;
    content: '';
    transform: translate(-100%, -100%);
  }

  &:not(:last-child) {
    margin-bottom: $spacing-sm;
  }
}

/* stylelint-disable order/properties-alphabetical-order */
:export {
  varSpotMainColor: hex-to-rgb($c-menu-bg);
  varSpotSecondaryColor: hex-to-rgb($c-bg);
  varSpotIsLight: false;
}
