@mixin modal-with-top-offset($top-offset) {
  @include fixed($top-offset 0 x 0);
  @include size(100%, calc(var(--full-height, 100vh) - #{$top-offset}));

  @supports (padding-top: env(safe-area-inset-top)) {
    // If on iPhone X, add space for the safe area inset size
    height: calc(var(--full-height, 100vh) - #{$top-offset} - env(safe-area-inset-top, 0));
    top: calc(#{$top-offset} + env(safe-area-inset-top, 0));
  }

  @include android-chrome {
    // On Android Chrome, the browser UI cuts into the viewport.
    height: calc(100vh - #{$android-chrome-address-bar-height});
  }

  // If in PWA, just use 100vh as standard
  @media (display-mode: standalone) {
    height: calc(100vh - #{$top-offset});

    @supports (padding-top: env(safe-area-inset-top)) {
      height: calc(100vh - #{$top-offset} - env(safe-area-inset-top, 0));
    }
  }
}
