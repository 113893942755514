@import 'abstracts';
.alert {
  @include fixed(0);
  @include size(100%);
  @include flex(column, 1);
  align-items: center;
  background-color: rgba($c-bg, 0.9);
  justify-content: center;
  z-index: $z-alert;
}

.alert__text {
  @include h2;
  margin-bottom: $spacing-base;
  text-align: center;
}

.alert__loader {
  color: $c-text;
}
