@import 'abstracts';
.menu {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
}

.menu__inner {
  @include flex(column, 1);
  max-width: $content-max-width;
  width: 100%;
}

.menu__header {
  @include hide;
}

.menu-list {
  margin-bottom: $spacing-sm;
}

.menu-link,
.secondary-link {
  @include flex(row);
  @include selection($c-blue-text);
  color: $c-bg;
  width: 100%;
}

.menu-link {
  @include h1;
  @include padding(12px x);

  .menu-item & {
    border-bottom: 2px solid $c-disabled-bg;
    border-top: 2px solid $c-disabled-bg;
    margin-bottom: -2px;
  }
}

.secondary-link {
  @include body;
  @include reset-input;
  @include padding(6px x);
}
