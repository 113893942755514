@import 'abstracts';
.modal-nav__main {
  @include flex(column);
  color: $c-bg;
  height: inherit;
  margin-top: $spacing-sm + $nav-size + $spacing-base; // Offset by nav position/size
  outline: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;
  width: 100%;

  &::after {
    @include site-spacing(bottom);
    content: '';
    height: 0;
  }

  &--with-transition {
    cursor: progress;
  }
}

.modal-nav__main-content {
  @include absolute(0 x x 0);
  @include flex(column, 1);
  opacity: 0;
  padding-top: $spacing-sm;
  width: 100%;
}
