@import 'abstracts';
$banner-padding: $spacing-sm;
$app-icon-size: $android-download-banner-height - 2 * $banner-padding;

.banner {
  align-items: center;
  background-color: $c-gray;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;
}

.close {
  background-color: transparent;
  border: 0;
  box-sizing: content-box;
  font-size: 30px;
  height: 22px;
  line-height: 0;
  margin: 0;
  padding: $banner-padding;
  width: 28px;
}

.banner-link {
  align-items: center;
  color: $c-black;
  display: flex;
  height: $android-download-banner-height;
  padding: $banner-padding;
}

.banner-link__img {
  @include size($app-icon-size);
  border-radius: 4px;
  flex: none;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.banner-link__text {
  line-height: 1.125;
  margin-left: $banner-padding;
}
