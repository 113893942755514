@import 'abstracts';
$bp-island: $content-max-width;

.container {
  @include flex(column, 1);
  @include modal-with-top-offset($spacing-sm);
  z-index: $z-modal;

  @include mq($bp-island) {
    @include site-inline-spacing;
  }
}

.temp-modal {
  @include flex(column, 1);
  @include margin(x auto);
  align-items: center;
  height: inherit;
  max-width: $bp-island;
  position: relative;
  z-index: $z-modal;
}

.temp-modal-inner {
  @include flex(column, 1);
  @include size(100%, inherit);
  background-color: $c-white;
  border-top-left-radius: $page-radius;
  border-top-right-radius: $page-radius;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  visibility: hidden;

  @include mq($bp-island) {
    box-shadow: $spacing-sm;
  }
}

.temp-modal__content {
  @include site-inline-spacing;
  @include size(100%, inherit);
  color: $c-dark-blue;
  overflow-y: auto;
  padding-bottom: $spacing-md;
  padding-top: $spacing-sm;
}

.temp-modal__header {
  padding-top: $spacing-xl;
}

.temp-modal__header__title {
  @include page-label;
  @include selection($c-white, $c-blue);
}

.temp-modal__header__description {
  @include h1;
  @include selection($c-white, $c-bg);
}

.temp-modal__close {
  @include reset-input;
  @include button(md, $theme: light);
  position: absolute;
  right: $spacing-sm;
  top: $spacing-sm;
  z-index: 2;
}

.temp-modal__close__icon {
  display: block;
  height: $spacing-base;
  margin-top: 5%;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.temp-modal__close__text {
  @include hide;
}

.free-text {
  @include free-text($theme: light);
  margin-top: $spacing-md;

  * {
    @include selection($c-white, $c-bg);
  }

  a {
    border-color: $c-light-blue;
  }
}

.notice {
  border-top: 2px solid $c-blue;
  font-style: italic;
  padding-top: $spacing-base;
}

// Temp Fail

.temp-fail__stop-hand {
  @include size($spacing-lg);
  margin-bottom: $spacing-md;
}

.temp-fail__stop-text {
  @include size(0);
  left: -1000px;
  overflow: hidden;
  position: absolute;
  top: -1000px;
}
