@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  @include site-spacing(bottom);
  align-items: center;
}

.page-inner {
  max-width: $content-max-width;
  width: 100%;
}
