@import 'abstracts';
.loading-indicator {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: $spacing-base;
}

.page {
  @include flex(column, 1);
  align-items: center;
  overflow-x: hidden;

  @include device($iPhone678Plus, max) {
    margin-top: -40px;
  }
}

.page__topper {
  @include flex(column, 1);
  @include left-inset-spacing;
  max-width: $content-max-width;
  position: relative;
  width: 100%;
}

.page__topper__header {
  margin-bottom: $spacing-base;
}

.title {
  @include site-spacing(right);
  @include jumbo;

  span,
  time {
    @include selection(rgb(var(--spot-color)), var(--spot-text-color, hex-to-rgb($c-text)));
    color: rgb(var(--spot-text-color, hex-to-rgb($c-text)));
  }
}

.title__weekday {
  display: block;
}

.description {
  @include site-spacing(right);
  @include selection(rgb(var(--spot-color)), var(--spot-text-color, hex-to-rgb($c-text)));
  color: rgb(var(--spot-text-color, hex-to-rgb($c-text)));

  .title + & {
    margin-top: $spacing-base;
  }
}

.building {
  @include flex(column, 1);
  background-color: rgb(var(--spot-color));
  min-height: $photo-min-height;
  position: relative;
  width: 100%;

  &::before {
    @include absolute(0);
    @include size(100%);
    background-color: rgba($c-white, 0.15);
    content: '';
    mix-blend-mode: overlay;
  }
}

.building__inner {
  @include absolute(0);
  @include size(100%);
}

.building__inner__image {
  @include size(100%);
  object-fit: cover;
  object-position: top center;
  position: relative;
}

$spot-colors: (
  Sunday: (
    main: #00b463,
    secondary: #34c986,
    is-light: false,
  ),
  Monday: (
    main: #ffd028,
    secondary: #f4be00,
    is-light: true,
  ),
  Tuesday: (
    main: #ff7171,
    secondary: #ff9292,
    is-light: false,
  ),
  Wednesday: (
    main: #bb71b0,
    secondary: #d189c6,
    is-light: false,
  ),
  Thursday: (
    main: #62e5f0,
    secondary: #2dd1de,
    is-light: true,
  ),
  Friday: (
    main: #ffaef7,
    secondary: #fc92f1,
    is-light: true,
  ),
  Saturday: (
    main: #cbcc09,
    secondary: #b3b400,
    is-light: true,
  ),
);

:export {
  @each $day, $spec in $spot-colors {
    var#{$day}MainColor: hex-to-rgb(map-get($spec, main));
    var#{$day}SecondaryColor: hex-to-rgb(map-get($spec, secondary));
    var#{$day}IsLight: map-get($spec, is-light);
  }
}
