@import 'abstracts';
.question {
  @include flex(column, 1);
}

.question__header {
  @include flex(column, 1);
  margin-bottom: $spacing-base;

  @include device($iPhone678) {
    margin-bottom: $spacing-lg;
  }
}

.question__header__title {
  @include h1;
  @include page-label;
}

.question__header__question {
  @include h1;
  margin-bottom: $spacing-base;

  @include device($iPhone678) {
    margin-bottom: 25px;
  }
}

.question__options {
  @include flex(row);
  justify-content: center;
  margin-bottom: $spacing-xs;
  width: 100%;
}

.question__options__item {
  @include button;

  &:not(:last-child) {
    margin-right: $spacing-base;
  }
}
