h1 {
  @include h1;
}

h2 {
  @include h2;
}

p,
li {
  @include body;
}

small {
  @include small;
}
