@mixin flex($direction: '', $grow: '') {
  @if $direction != '' {
    display: flex;

    @if $direction != row {
      // Default direction is row, so don't need to explicitly define
      flex-direction: $direction;
    }
  }

  @if $grow != '' {
    // This is a fix for IE11, which has the default values `0 1 0`
    // @see https://stackoverflow.com/a/22883146
    flex: $grow 0 auto;
  }
}
