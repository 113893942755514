@mixin mq($bp-1, $bp-2: null) {
  @if $bp-1 == 'max' {
    @media (max-width: $bp-2 - 1) {
      @content;
    }
  } @else if $bp-2 == 'max' {
    @media (max-width: $bp-1 - 1) {
      @content;
    }
  } @else if $bp-2 == null {
    @media (min-width: $bp-1) {
      @content;
    }
  } @else {
    @media (min-width: $bp-1) and (max-width: $bp-2 - 1) {
      @content;
    }
  }
}

// Takes a map or a list of maps containing keys 'width', 'height',
// and constructs media queries for devices with those dimensions
@mixin device($devices, $threshold: 'min') {
  // If only a single device is provided as a target
  @if type-of($devices) == map {
    @if $threshold == 'min' {
      @media (min-width: map-get($devices, 'width')) and (min-height: map-get($devices, 'height')) {
        @content;
      }
    } @else if $threshold == 'max' {
      @media (max-width: map-get($devices, 'width') - 1) and (max-height: map-get($devices, 'height') - 1) {
        @content;
      }
    }
    // If a list of devices is provided as a target
  } @else if type-of($devices) == list {
    @each $device in $devices {
      @if $threshold == 'min' {
        @media (min-width: map-get($device, 'width')) and (min-height: map-get($device, 'height')) {
          @content;
        }
      } @else if $threshold == 'max' {
        @media (max-width: map-get($device, 'width') - 1) and (max-height: map-get($device, 'height') - 1) {
          @content;
        }
      }
    }
  }
}

@mixin android-chrome {
  :global(.androidChrome) & {
    @content;
  }
}
