@import 'abstracts';
.locations {
  @include flex(column, 1);
  align-items: center;
  width: 100%;
}

.locations-nav {
  @include flex(row);
  justify-content: center;
  width: 100%;
}

.locations-nav-inner {
  @include nav;
}

.locations-nav__item {
  @include flex(column);
  align-items: center;
  flex-basis: calc(100% / 3);

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }
}

.locations-nav__button {
  @include button-link;
}

.locations-nav__help-link {
  @include button-link;
}

.locations__main {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
  outline: none;
  width: 100%;

  &--with-transition {
    cursor: wait;
  }
}

.locations__screen {
  @include flex(column, 1);
  @include absolute(x x x -5000px);
  @include site-spacing(bottom);
  @include size(0);
  max-width: $content-max-width;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
}
