@import 'abstracts';
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  color: $c-blue;
}

.loader {
  @include size($spacing-md);
  animation: spin 0.7s infinite linear;
  border: 2px solid transparent;
  border-left-color: currentColor;
  border-radius: 50%;
  border-top-color: currentColor;
}

.label {
  @include hide;
}
