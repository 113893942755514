@import 'abstracts';
.loading-indicator {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: $spacing-base;
}

@keyframes fade-page {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.page {
  @include flex(column, 1);

  &--with-delay {
    // Delays the visibility of the page by 300ms
    animation: fade-page 300ms ease-out 1;
  }
}
