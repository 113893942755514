@import 'abstracts';
.pagination {
  position: relative;
}

.pagination__list {
  @include flex(row);
  justify-content: center;
}

.pagination__list__item {
  @include flex(row);

  &:not(:last-child) {
    margin-right: 4px;
  }
}

$dot-size: 6px;

.dot {
  @include size($dot-size);
  background-color: $c-blue;
  border-radius: $dot-size / 2;
  flex-shrink: 0;
  outline: none;
  transition: background-color 200ms ease-out;

  &--light {
    background-color: $c-light-blue;
  }

  &--active {
    background-color: $c-white;
    cursor: default;

    &.dot--light {
      background-color: $c-blue;
    }
  }
}
