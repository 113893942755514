/* Colors */
$c-white: #ffffff;
$c-light-blue: #c7d3ff;
$c-gray-blue: #7689d1;
$c-bright-blue: #3c69ff;
$c-blue: #0e3cd9;
$c-dark-blue: #001970;
$c-gray: #dadada;
$c-black: #000000;

/* Colors - Applied */
$c-bg: $c-dark-blue;
$c-menu-bg: $c-blue;
$c-disabled-bg: $c-light-blue;
$c-text: $c-white;
$c-blue-text: $c-bright-blue;
$c-disabled-text: $c-gray-blue;
$c-light-text: $c-light-blue;
$c-highlight: rgba($c-white, 0.4);

/* Box Shadow */
$shadow-big: 0 10px 40px rgba($c-black, 0.26);
$shadow-light: 0 8px 17px rgba(#003aff, 0.15);

/* Font Families */
$font-display: 'Halyard Display Web';
$font-text: 'Halyard Text Web';

/* Fonts - Default */
$ff-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$ff-serif: Georgia, 'New York', 'Times New Roman', Times, serif;
$ff-mono: 'SF Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;

/* Fonts - Custom */
$ff-display: $font-display, $ff-sans;
$ff-text: $font-text, $ff-sans;

/* Font Sizes */
$fs-xxs: 12px;
$fs-xs: 13px;
$fs-sm: 16px;
$fs-base: 20px;
$fs-md: 24px;
$fs-lg: 30px;
$fs-xl: 48px;
$fs-xxl: 57px;

/* Spacing */
$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-sm: 15px;
$spacing-base: 20px;
$spacing-md: 30px;
$spacing-lg: 45px;
$spacing-xl: 60px;

/* Sizes */
$btn-sm: 38px;
$btn-md: 50px;
$btn-lg: 100px;
$btn-xl: 125px;
$nav-size: $btn-md;
$photo-min-height: 180px;
$content-max-width: 500px;
$page-radius: $spacing-xs;

/* Breakpoints */
$bp-xxs: 320px;
$bp-xs: 400px;
$bp-sm: 500px;
$bp-md: 800px;

/* Apple Devices */
$iPhoneSE: (
  'width': 320px,
  'height': 568px,
);

$iPhone678: (
  'width': 375px,
  'height': 553px,
); // iPhone 6, 7, 8, iPhone SE 2020

$iPhone678Plus: (
  'width': 414px,
  'height': 622px,
);

$iPhoneX: (
  'width': 375px,
  'height': 635px,
); // iPhone X, iPhone XS, iPhone 11 Pro

$iPhone11: (
  'width': 414px,
  'height': 719px,
); // iPhone 11, iPhone 11 Pro Max, iPhone XS Max, iPhone XR

/* Android Devices */
$Pixel: (
  'width': 412px,
  'height': 660px,
); // Pixel, Pixel XL, Pixel 2

$Pixel3: (
  'width': 393px,
  'height': 658px,
);

$Pixel3XL: (
  'width': 412px,
  'height': 749px,
);

/* Easing */
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);

$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

/* Z-indices */
$z-menu-btn: 1000;
$z-menu: 1001;
$z-modal: 1002;
$z-skip-to-content: 1003;
$z-splash: 9998;
$z-splash-content: 9999;
$z-alert: 10000;

/* Misc */
$android-chrome-address-bar-height: 56px;
$android-download-banner-height: 80px;
