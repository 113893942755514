@import 'abstracts';
.form-wrapper {
  @include flex(column, 1);
  align-items: center;
  width: 100%;
}

.form-nav {
  @include flex(row);
  justify-content: center;
  width: 100%;
}

.form-nav-inner {
  @include nav;
}

.form-nav__item {
  @include flex(column);
  align-items: center;
  flex-basis: calc(100% / 3);

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }
}

.form-nav__button {
  @include button-link;
}

.form-loader {
  @include absolute(50% x x 50%);
  transform: translate(-50%, -50%);
}

.form-nav__help-link {
  @include button-link;
}
