h1,
h2,
h3,
li,
p,
span,
button,
a {
  @include selection($c-bg, $c-text);
}

body {
  @include margin(0);
  background-color: $c-bg;
  color: $c-text;
  transition: background-color 300ms 300ms $ease-out-sine;

  &.body--light {
    background-color: $c-bright-blue;
    transition: background-color 300ms 0ms $ease-out-sine;
  }

  &.body--spot {
    background-color: rgb(var(--spot-color, hex-to-rgb($c-bg)));
    transition: background-color 300ms 0ms $ease-out-sine;

    &.body--light {
      background-color: rgb(var(--spot-secondary-color, hex-to-rgb($c-bright-blue)));
    }
  }
}
