@import 'abstracts';
.page {
  @include flex(column, 1);
  width: 100%;
}

.page__header {
  @include big-spacing(margin-bottom);
  @include flex(column, 1);
}

.title {
  @include h1;
  margin-bottom: 2px;

  span {
    display: block;
  }
}

.code {
  @include h1;
  @include page-label;
}

.retry-button {
  @include button;
  @include margin(x auto);
  font-size: $fs-base;
  margin-bottom: $spacing-xs;
}
