@mixin free-text($theme: dark) {
  $highlight-color: $c-blue-text;
  @if $theme == light {
    $highlight-color: $c-light-text;
  }

  @include flex(column);
  align-items: flex-start;

  strong {
    font-weight: 500;
  }

  h3 {
    @include h2;
  }

  h3,
  p,
  ul,
  ol,
  > a {
    + p,
    + ul,
    + a {
      margin-top: $spacing-sm;
    }
  }

  h3,
  p,
  ul,
  ol,
  > a {
    + h3,
    + h2 {
      margin-top: 40px;
    }
  }

  ul {
    li {
      padding-left: 1.4em;
      position: relative;

      &::before {
        @include absolute(0.4em x x 0.25em);
        @include size(0.5em);
        background-color: $c-blue;
        border-radius: 50%;
        content: '';
      }

      + li {
        margin-top: 3px;
      }
    }
  }

  a {
    box-shadow: inset 0 -1px $highlight-color;
    color: currentColor;
    transition: 200ms $ease-out-cubic;

    &:hover,
    &:focus {
      box-shadow: inset 0 -9px $highlight-color;
    }
  }

  > a {
    @include body;
    @include flex(row);
    align-items: center;
    padding-bottom: 2px;

    span {
      @include size($fs-xs);
      margin-left: 3px;
      transform: scaleX(-1);
      transition: 100ms ease-out;
    }

    &:focus {
      span {
        transform: translateX(2px) scaleX(-1);
      }
    }
  }
}
