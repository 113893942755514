@import 'abstracts';
.review {
  @include flex(column, 1);
  margin-bottom: $spacing-base;
  width: 100%;

  @include device($iPhone678) {
    margin-bottom: $spacing-lg;
  }
}

.review__header {
  @include flex(column);
  margin-bottom: $spacing-base;

  @include device($iPhone678) {
    margin-bottom: 25px;
  }
}

.review__header__title {
  @include h1;
  @include page-label;
}

.review__header__subtitle {
  @include h1;
}

.review__table {
  @include flex(column);
  background-color: $c-bg;
  border-radius: $spacing-sm;
  box-shadow: $shadow-big;
  margin-bottom: $spacing-xs;

  @include device($iPhone678) {
    margin-bottom: $spacing-sm;
  }
}

$border-width: 2px;

.review__table__row {
  &:first-child {
    .response {
      border-top-left-radius: $spacing-sm;
      border-top-right-radius: $spacing-sm;
    }
  }

  &:last-child {
    .response {
      border-bottom-left-radius: $spacing-sm;
      border-bottom-right-radius: $spacing-sm;
    }
  }

  &:not(:last-child) {
    .response {
      margin-bottom: -$border-width;
    }
  }
}

.response {
  @include flex(row);
  @include reset-input;
  @include padding($spacing-xs);
  align-items: center;
  border: $border-width solid $c-bright-blue;
  box-shadow: focus-highlight(0);
  color: $c-text;
  cursor: pointer;
  outline: none;
  position: relative;
  text-align: left;
  transition: box-shadow 100ms $ease-out-quad;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $c-text;
    color: $c-bg;
  }

  &:focus {
    box-shadow: focus-highlight(2px);
    z-index: 20;
  }
}

.response__question {
  @include body;
  @include flex($grow: 1);
}

.hide {
  @include hide;
}

.response__answer {
  @include button(sm, false);
  margin-left: $spacing-base;
}

.review__notice {
  @include padding(x $spacing-xs);
  @include small;
  max-width: 325px;

  @include device($iPhone678) {
    width: 80%;
  }
}
