@import 'abstracts';
.locations__title {
  @include page-label;
}

.locations__question {
  @include h1;
}

.locations__list {
  @include flex(column);
  background-color: $c-bg;
  border-radius: $spacing-sm;
  box-shadow: $shadow-big;
  margin-bottom: $spacing-sm;
  margin-top: $spacing-md;
}

$border-width: 2px;

.locations__list__row {
  &:first-child {
    .locations__list__item {
      border-top-left-radius: $spacing-sm;
      border-top-right-radius: $spacing-sm;
    }
  }

  &:last-child {
    .locations__list__item {
      border-bottom-left-radius: $spacing-sm;
      border-bottom-right-radius: $spacing-sm;
    }
  }

  &:not(:last-child) {
    .locations__list__item {
      margin-bottom: -$border-width;
    }
  }
}

.locations__list__item {
  @include flex(row);
  @include reset-input;
  @include padding($spacing-xs $spacing-base);
  @include h1;
  align-items: center;
  border: $border-width solid $c-bright-blue;
  box-shadow: focus-highlight(0);
  color: $c-text;
  cursor: pointer;
  outline: none;
  position: relative;
  text-align: left;
  transition: box-shadow 100ms $ease-out-quad;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $c-text;
    color: $c-bg;
  }

  &:focus {
    box-shadow: focus-highlight(2px);
    z-index: 20;
  }
}
