@import 'abstracts';
.header {
  @include site-inline-spacing;
}

.header__title {
  @include page-label;
  @include selection($c-white, $c-blue);
}

.header__description {
  @include h1;
  @include selection($c-white, $c-bg);
}

.cta__button {
  @include reset-input;
  @include button($theme: illo);
}

.illustration__wrapper {
  @include flex(column, 1);
  @include site-block-spacing;
  align-items: center;
  justify-content: center;
}

.cta__button {
  margin-top: -50px;
  position: relative;
  z-index: 1;
}
