@import 'abstracts';
.page {
  @include flex(column, 1);
  @include site-inline-spacing;
  align-items: center;
}

.page-inner {
  max-width: $content-max-width;
  width: 100%;
}

.page-label {
  @include page-label;
}

.title {
  @include h1;
  @include page-title;

  span {
    display: block;
  }
}

.free-text {
  @include free-text(light);
}

.copyright {
  margin-top: $spacing-lg;

  span {
    display: block;
  }
}
