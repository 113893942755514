@import 'abstracts';
.header {
  @include site-inline-spacing;
}

.header__title {
  @include page-label;
  @include selection($c-white, $c-blue);
}

.header__description {
  @include h1;
  @include selection($c-white, $c-bg);
}

.steps {
  @include flex(column, 1);
  @include site-inline-spacing;
  @include margin($spacing-md x $spacing-lg);
  counter-reset: number;
  z-index: 2;
}

.steps__item {
  @include h2;
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: $spacing-xxs;
  }

  &::before {
    color: $c-blue-text;
    content: counter(number) '.';
    counter-increment: number;
  }
}

.steps__item__text {
  @include selection($c-white, $c-bg);
  padding-left: $spacing-xxs;
}

.footer {
  @include flex(column);
  @include site-spacing(right);
  align-items: center;
  width: 100%;
  z-index: 1;
}

.footer__illo {
  display: block;
  max-height: 350px;
  object-fit: contain;
  object-position: bottom left;
  opacity: 0.99;
  transform: scale(1.2);
  transform-origin: right bottom;
  width: 100%;
}

.footer__cta {
  @include absolute(x x $spacing-sm 50%);
  @include site-spacing(bottom);
  transform: translateX(-50%);

  @include device($iPhone678Plus, max) {
    bottom: 85px;
  }
}

.footer__cta__button {
  @include reset-input;
  @include button($theme: illo);
}
