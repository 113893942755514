/* Halyard Display */

@include font-face(
  $font-display,
  'halyard/HalyardDisplay-Book',
  300,
  normal,
  ('woff2', 'woff', 'otf')
);

@include font-face(
  $font-display,
  'halyard/HalyardDisplay-Regular',
  400,
  normal,
  ('woff2', 'woff', 'otf')
);

/* Halyard Text */

@include font-face(
  $font-text,
  'halyard/HalyardText-Regular',
  400,
  normal,
  ('woff2', 'woff', 'otf')
);

@include font-face(
  $font-text,
  'halyard/HalyardText-RegularItalic',
  400,
  italic,
  ('woff2', 'woff', 'otf')
);

@include font-face(
  $font-text,
  'halyard/HalyardText-SemiBold',
  500,
  normal,
  ('woff2', 'woff', 'otf')
);
