// Sizes: sm, md, lg

@mixin button($size: lg, $interactive: true, $theme: dark) {
  $highlight-size: 6px;
  @if $size == lg {
    @include size($btn-lg);
    font-family: $ff-display;
    font-size: $fs-sm;
    font-weight: 400;
    line-height: 1.1;

    @include device($iPhone678) {
      @include size($btn-xl);
      font-size: $fs-md;
    }
  } @else if $size == md {
    $highlight-size: 4px;
    @include small;
    @include size($btn-md);
  } @else if $size == sm {
    $highlight-size: 3px;
    @include small;
    @include size($btn-sm);
  }

  @if $theme == illo {
    font-size: $fs-base;
  }

  $shadow: $shadow-big;
  $bg-color: $c-blue;
  $text-color: $c-white;
  $highlight-color: $c-highlight;
  @if $theme == light {
    $text-color: $c-blue;
    $bg-color: $c-white;
    $shadow: $shadow-light;
    $highlight-color: rgba($c-blue, 0.4);
  } @else if $theme == illo {
    $highlight-color: mix($c-white, $c-blue, 80%);
  }

  @include flex(row);
  @include reset-input;
  align-items: center;
  background-color: $bg-color;
  border-radius: 50%;
  box-shadow: $shadow, focus-highlight(0, $highlight-color);
  color: $text-color;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-align: center;
  transition: box-shadow 100ms $ease-out-quad, transform 100ms $ease-out-sine;

  // optically align to vertical center
  @if $size == lg {
    padding-bottom: 0.25em;
  }

  @if $interactive == true {
    @if $theme != illo {
      &:hover,
      &:focus {
        background-color: $text-color;
        color: $bg-color;
      }
    }

    &:focus {
      box-shadow: $shadow, focus-highlight($highlight-size, $highlight-color);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &:disabled {
    background-color: $c-disabled-bg;
    color: $c-disabled-text;
    cursor: not-allowed;
  }

  &,
  span {
    @include selection($c-blue-text);
  }
}
