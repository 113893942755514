@import 'abstracts';
.loading-indicator {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: $spacing-base;
}

.home {
  @include flex(column, 1);
  align-items: center;
  width: 100%;

  @include device($iPhone678, max) {
    margin-top: $nav-size / -2;
  }
}

.home__header {
  @include flex(row);
  justify-content: center;
  width: 100%;
}

.home__header__inner {
  @include nav;
}

.home__header__inner__label {
  @include small;
  text-align: center;
  width: 100%;
}

.home__content {
  @include flex(column, 1);
  @include left-inset-spacing;
  max-width: $content-max-width;
  width: 100%;
}

.home__content__title {
  @include flex(column);
  margin-bottom: $spacing-base;
}

.home__content__description {
  @include site-spacing(right);
  margin-bottom: $spacing-sm;
}

.portrait {
  @include flex(column, 1);
  background-color: $c-blue;
  min-height: $photo-min-height;
  position: relative;
}

.portrait__container {
  @include absolute(0);
  @include size(100%);
}

.portrait__container__image {
  @include size(100%);
  object-fit: cover;
  object-position: top center;
}

.home__footer {
  @include flex(column);
  @include site-spacing(bottom);
  align-items: center;
  margin-top: $btn-lg / -2; // half button height
  width: 100%;
  z-index: 1;

  @include device($iPhone678) {
    margin-top: $btn-xl / -2;
  }
}

.survey-link {
  @include button;
  flex-direction: column;
  padding-bottom: 0.3em;

  @include device($iPhone678) {
    font-size: $fs-base;
  }
}
