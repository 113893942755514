@import 'abstracts';
.modal-open {
  @include button(md);
  @include selection($c-blue, $c-text);
  background-color: $c-blue;
  color: $c-text;
  z-index: $z-menu-btn;

  &--with-spot {
    @include selection(
      rgb(var(--spot-color, hex-to-rgb($c-blue))),
      var(--spot-text-color, hex-to-rgb($c-text))
    );
    background-color: rgb(var(--spot-color, hex-to-rgb($c-blue)));
    color: rgb(var(--spot-text-color, hex-to-rgb($c-text)));
  }

  &:hover,
  &:focus {
    @include selection($c-text, $c-blue);
    color: $c-blue;
  }
}

.modal-nav-backdrop {
  @include reset-input;
  @include fixed(0);
  @include size(100%);
  background: transparent;
  cursor: pointer;
}

.modal-nav {
  @include modal-with-top-offset($spacing-sm + $spacing-xs);
  @include flex(column, 1);
  align-items: center;
  background-color: $c-white;
  border-top-left-radius: $page-radius;
  border-top-right-radius: $page-radius;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  visibility: hidden;
  z-index: $z-menu;
}

.modal-nav__header {
  @include fixed(0 x x x);
  @include flex(row);
  @include site-inline-spacing;
  @include padding($spacing-sm x $spacing-xxs x);
  border-top-left-radius: $page-radius;
  border-top-right-radius: $page-radius;
  flex-shrink: 0;
  justify-content: center;
  overflow: initial;
  width: 100%;
  z-index: 2;

  &::after {
    @include absolute(x x 0 0);
    @include size(100%, $spacing-sm);
    background: linear-gradient(to bottom, $c-white, rgba($c-white, 0));
    content: '';
  }
}

.modal-nav__header__inner {
  @include nav;

  @include device(($iPhone678Plus, $iPhoneX), max) {
    margin-bottom: $spacing-md;
  }
}

.modal-nav__menu-link {
  @include button-link(light);
}

.modal-nav__close {
  @include reset-input;
  @include button(md, $theme: light);
}

.modal-nav__close__icon {
  display: block;
  height: $spacing-base;
  margin-top: 5%;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.modal-nav__close__text {
  @include hide;
}
