@import 'abstracts';
.cta {
  @include site-spacing(bottom);
  @include padding($spacing-sm x x x);
  @include flex(row);
  background-color: $c-white;
  justify-content: center;
  max-width: $content-max-width;
  outline: none;
  position: relative;
  width: 100%;

  &::after {
    @include absolute(0 x x 50%);
    @include size(110vw, 100%);
    background-color: $c-white;
    content: '';
    transform: translateX(-60vw);
    z-index: -2;
  }

  &--bleed {
    $bleed-offset: 40px;

    &::after {
      @include size(110vw, calc(100% + #{$bleed-offset}));
      top: -$bleed-offset;
    }
  }
}

.cta__inner {
  @include flex(row);
  @include left-inset-spacing;
  @include site-spacing(right);
  align-items: center;
  width: 100%;
}

.cta__inner__content {
  @include flex(column, 1);
  @include body;
  @include selection($c-white, $c-bg);
  color: $c-bg;

  span {
    font-weight: 500;
  }
}

button.cta__inner__content {
  @include reset-input;
  cursor: pointer;
}

$highlight-color: rgba($c-blue, 0.4);

.cta__download__link {
  @include size($spacing-lg);
  border-radius: 4px;
  box-shadow: $shadow-light;
  margin-right: $spacing-sm;
  overflow: hidden;
}

.cta__app__icon {
  @include size(100%);
}

.cta__link {
  @include reset-input;
  @include button(md, $theme: light);
  margin-right: $spacing-sm;
  position: relative;
}

.cta__link__icon {
  display: block;
  height: $spacing-sm;
  margin-left: 5%;
  transform: rotateY(180deg);
  transition: 200ms ease-out;
  transition-property: transform, opacity;

  .cta__link--with-overlay & {
    opacity: 0;
    transform: rotateY(180deg) translateX(-10px);
  }
}

.cta__link__text {
  @include hide;
}

.cta__link__countdown {
  @include absolute(50% x x 50%);
  @include counter;
  color: $c-dark-blue;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  transform: translate(-50%, -50%) translateY(-2.5%) translateX(-10px);
  transition: 200ms 150ms ease-out;
  transition-property: transform, opacity;

  .cta__link:hover &,
  .cta__link:focus & {
    color: $c-white;
  }

  .cta__link--with-overlay & {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(-2.5%);
  }
}

.cta__text__link {
  color: $c-dark-blue;
}
